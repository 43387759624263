import { Tour } from '../../components';
import TempLandingPage from './TempLandingPage';

const Home = () => {
  return (
    <>
      <TempLandingPage />
      <div className="w-5/6 mx-auto py-10 md:w-3/4">
        <Tour />
      </div>
    </>
  );
};

export default Home;
