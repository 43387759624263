import { IndexHelmet } from './components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './components/FontLoader/FontLoader';

const App = () => {
  return (
    <Router>
      <IndexHelmet />
      <Switch>
        <Route path="*" component={AppRoutes} />
      </Switch>
    </Router>
  );
};
export default App;
