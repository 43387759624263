import { ToursTypes } from '../../features/Tours/types';

export const tourData: ToursTypes[] = [
  {
    index: 3,
    date: 'Mon, Aug 30, 2021',
    address: 'Glasgow, UK',
    venue: 'King Tuts',
    tickets: 'https://www.livenation.co.uk/artist-waterparks-914330',
    past: isTourHappened('Mon, Aug 30, 2021'),
    tickets2: 'test',
  },
];

function isTourHappened(date: string) {
  const presentTime = new Date().getTime();
  const targetTime = new Date(date).getTime();

  return presentTime > targetTime;
}
