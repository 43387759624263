import TourItem from './TourItem';
import { useSelector } from 'react-redux';
import { getAllTours } from '../../features/Tours/Tours.selector';
import { useEffect } from 'react';
import useTourController from '../../app/Hooks/useTourController';
import useToursService from '../../app/Hooks/useToursService';
import { tourData } from '../../api/Controller/TourData';

export default function Tour() {
  const { getTourItems } = useTourController();
  const { setToursItems } = useToursService();

  const data = useSelector(getAllTours);

  useEffect(() => {
    const tourItems = async () => {
      try {
        const tourItems = await getTourItems();

        if (!tourItems) return;
        setToursItems(tourItems);
      } catch (error) {
        // dummy/backup data
        setToursItems(tourData);
      }
    };

    tourItems();
  }, [getTourItems, setToursItems]);

  return (
    <section className="font-arial">
      <div className="px-5 w-full flex flex-col justify-between mx-auto mt-10">
        {data.map((item) => {
          if (!item.index || item.past) return null;
          return (
            <TourItem
              key={item.index}
              tickets={item.tickets}
              tickets2={item.tickets2}
              date={item.date}
              address={item.address}
              venue={item.venue}
            />
          );
        })}
      </div>
    </section>
  );
}
