import { useEffect, useMemo } from "react";
import { TourController } from "../../api";

export default function useTourController() {
  useEffect(() => {
    let cleanupFns: (() => void)[] = [];
    const { getTourItems } = new TourController();

    cleanupFns = [getTourItems];

    return () => {
      cleanupFns.map((fn) => fn());
    };
  }, []);

  return useMemo(() => new TourController(), []);
}
