// import { Timer } from '../../components';
import BGDesktop_IMG from '../../assets/images/bg.desktop.png';
import BGMobile_IMG from '../../assets/images/bg.mobile.png';

const TempLandingPage = () => {
  return (
    <div className="min-h-screen">
      <div className="flex flex-col justify-center items-center h-full min-h-screen relative">
        {/* <Timer /> */}
        <div className="mt-auto mb-36 md:mb-10">
          <span className="text-white text-xl font-bold">$NOT - Doja (feat. A$AP Rocky)</span>
          <div className="mt-2 text-center">
            <a
              href="https://snot.ffm.to/doja"
              className="bg-white text-black py-1 px-10 text-center border border-white hover:text-white hover:bg-transparent"
            >
              PRE-SAVE
            </a>
          </div>
        </div>
      </div>
      <div className="fixed top-0 left-0 w-ful h-full -z-10">
        <img
          src={BGDesktop_IMG}
          alt="bg"
          className="object-cover w-screen h-screen hidden sm:block"
        />
        <img
          src={BGMobile_IMG}
          alt="bg"
          className="object-cover h-screen w-screen sm:hidden block"
        />
      </div>
    </div>
  );
};

export default TempLandingPage;
