interface TourItemProps {
  date: string;
  address: string;
  venue: string;
  tickets: string; // a link
  tickets2: string | null;
}

export default function TourItem({ date, address, venue, tickets, tickets2 }: TourItemProps) {
  return (
    <div className="flex flex-row justify-between items-center py-4 border-t border-b border-gray-400">
      <a
        className="flex flex-col w-full text-white text-sm"
        href="https://www.bandsintown.com/e/1021426769?affil_code=js_127.0.0.1&app_id=js_127.0.0.1&came_from=242&utm_campaign=event&utm_medium=web&utm_source=widget"
        target="_blank"
        rel="noreferrer"
      >
        <span className="font-bold uppercase">{date}</span>
        <div className="mr-auto flex flex-col mt-2 md:mt-0 md:flex-row w-full">
          <span className="md:w-2/5">{address}</span>
          <div className="md:mr-auto">{venue}</div>
        </div>
      </a>
      <div className="flex flex-col">
        <span className="hidden md:block opacity-0">dummy</span>
        <div className="flex flex-col items-start md:flex-row justify-start w-full md:w-auto">
          {tickets2 && (
            <a
              className="w-full text-center mr-5 mt-2 md:mt-0 md:w-auto bg-white py-1 px-8 text-black border rounded-sm text-sm hover:text-white hover:border-white hover:bg-black"
              target="_blank"
              rel="noreferrer"
              href={tickets2 ? tickets2 : ''}
              aria-label="Tickets"
            >
              <span>Stream</span>
            </a>
          )}
          <a
            className="w-full text-center mr-5 mt-2 md:mt-0 md:w-auto bg-white py-1 px-8 text-black border rounded-sm text-sm hover:text-white hover:border-white hover:bg-black"
            target="_blank"
            rel="noreferrer"
            href={tickets}
            aria-label="Tickets"
          >
            <span>Tickets</span>
          </a>
          {/* <a
            className="w-full text-center mt-2 md:mt-0 md:w-auto bg-white py-1 px-11 text-black border rounded-sm text-sm hover:text-white hover:border-white hover:bg-black"
            target="_blank"
            rel="noreferrer"
            href="https://vip.waterparksband.com/"
            aria-label="Tickets"
          >
            <span>VIP</span>
          </a> */}
        </div>
      </div>
    </div>
  );
}
